<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Курсы</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VRow>
          <VCol>
            <h2>Добавить курс</h2>
          </VCol>
        </VRow>
        <VForm
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol>
              <TTTextField
                v-model.trim="course.name"
                :label="$t('CreateCourse.courseName')"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTTextarea
                v-model.trim="course.description"
                :label="$t('CreateCourse.description')"
                class="mt-2"
                x-small
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTAutocomplete
                v-model="selectedTopics"
                :items="topics"
                item-text="name"
                item-value="id"
                :label="$t('CreateCourse.chooseTopics')"
                chips
                multiple
                large
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <CourseLabels
                :added-labels="course.labels"
                @click:add="addLabel"
                @click:remove="removeLabel"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTBtn
                :loading="courseLoading"
                :disabled="!isValid || courseLoading"
                @click="submit"
              >
                Создать курс
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <TTDataTable
          :headers="headers"
          :items="coursesItems.values"
          :loading="coursesItems.loading"
          disable-pagination
          disable-sort
          class="course-table"
          :item-class="itemClass"
        >
          <template #item.id="{ item }">
            <span class="word-break-all">
              {{ item.id }}
            </span>
          </template>

          <template #item.name="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>

          <template #item.description="{ item }">
            <span>
              {{ item.description }}
            </span>
          </template>

          <template #item.status="{ item }">
            <span class="word-break-all">
              {{ item.status }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                  @click="goToEdit(item.id)"
                >
                  <VIcon small>
                    $edit
                  </VIcon>
                </VBtn>
              </template>
              <span>Редактирование</span>
            </VTooltip>
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="openDialog(item.id)"
                >
                  <VIcon small>
                    $archive
                  </VIcon>
                </VBtn>
              </template>
              <span>Архивировать</span>
            </VTooltip>
            <VTooltip
              v-if="item.status === CourseStatus.STATUS_BLOCKED"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click="activateCourse(item.id)"
                >
                  <VIcon small>
                    fal fa-check
                  </VIcon>
                </VBtn>
              </template>
              <span>Активировать</span>
            </VTooltip>
            <VTooltip
              v-if="item.status === CourseStatus.STATUS_ACTIVE"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="danger"
                  v-bind="attrs"
                  v-on="on"
                  @click="openBlockDialog(item.id)"
                >
                  <VIcon small>
                    fal fa-ban
                  </VIcon>
                </VBtn>
              </template>
              <span>Заблокировать</span>
            </VTooltip>
          </template>
        </TTDataTable>
        <VPagination
          v-if="coursesItemsPagesTotal > 0"
          v-model="coursesItems.page"
          total-visible="10"
          :length="coursesItemsPagesTotal"
        />
        <VDialog
          v-model="confirmDialog"
          max-width="500px"
          @click:outside="hideDialog"
        >
          <VCard>
            <VCardTitle class="headline">
              Архивировать курс
            </VCardTitle>
            <VCardText>
              Этот курс будет архивирован! <br>
              Действительно хотите архивировать курс?
            </VCardText>
            <VCardActions>
              <VSpacer />
              <VBtn
                text
                @click="hideDialog"
              >
                Нет
              </VBtn>
              <VBtn
                color="red darken-1"
                text
                @click="removeCourse"
              >
                Да
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
        <VDialog
          v-model="confirmBlockDialog"
          max-width="500px"
          @click:outside="hideBlockDialog"
        >
          <VCard>
            <VCardTitle class="headline">
              Заблокировать курс
            </VCardTitle>
            <VCardText>
              Этот курс будет заблокирован! <br>
              Действительно хотите заблокировать курс?
            </VCardText>
            <VCardActions>
              <VSpacer />
              <VBtn
                text
                @click="hideBlockDialog"
              >
                Нет
              </VBtn>

              <VBtn
                color="red darken-1"
                text
                @click="blockCourse(blockingCourseId)"
              >
                Да
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { required } from '@front.backoffice/common';
import { LevelNameMap, CourseStatus } from '../../common/constants';
import CourseLabels from '../../components/learning/CourseLabels.vue';

const courseLevels = Object.entries(LevelNameMap).map(([value, name]) => ({ name, value }));
const DEFAULT_COURSE = {
  name: '',
  description: '',
  topicIds: [],
  status: 'STATUS_INVALID', // По-умолчанию
  labels: [],
};

export default {
  name: 'CreateCourse',
  components: {
    CourseLabels,
  },
  inject: ['Names'],
  data() {
    return {
      course: { ...DEFAULT_COURSE },
      courseLoading: false,
      topicId: '',
      isValid: false,
      courseLevels,
      skills: [],
      coursesItems: {
        values: [],
        total: 0,
        page: 1,
        pageSize: 20,
        loading: false,
      },
      headers: [
        { text: 'Id', value: 'id', width: '20%' },
        { text: 'Название', value: 'name', width: '30%' },
        { text: 'Описание', value: 'description', width: '20%' },
        { text: 'Статус', value: 'status', width: '20%' },
        { text: 'Действия', value: 'actions', width: '10%' },
      ],
      confirmDialog: false,
      confirmBlockDialog: false,
      removingCourseId: null,
      blockingCourseId: null,
      topics: [],
      selectedTopics: [],
      CourseStatus,
    };
  },
  computed: {
    coursesItemsPagesTotal() {
      return Math.ceil(this.coursesItems.total / this.coursesItems.pageSize);
    },
    rules() {
      return {
        required: required(),
      };
    },
  },
  watch: {
    'coursesItems.page': {
      async handler(pageNumber) {
        const {
          courses = [],
          pagination: { total = 0 },
        } = await this.fetchCoursesItemsPage(pageNumber, this.coursesItems.pageSize);
        this.coursesItems.values = courses;
        this.coursesItems.total = total;
      },
      immediate: true,
    },
    selectedTopics: {
      handler(value) {
        this.course.topicIds = value;
      },
    },
  },
  async created() {
    await this.loadCourses();
    await this.fetchTopicsItems();
    this.course.labels = [];
  },
  methods: {
    submit() {
      this.$refs.form.validate();

      if (this.isValid) {
        this.createCourse();
      }
    },
    async loadCourses() {
      const { courses = [], pagination = {} } = await this.fetchCoursesItemsPage();
      this.coursesItems.values = courses;
      this.coursesItems.total = pagination.total;
    },
    async fetchTopicsItems() {
      try {
        const { topics } = await this.$di.api.TtCourse.searchTopics();
        this.topics = topics.map((topic) => ({
          key: topic.key,
          id: topic.id,
          name: `NAME: ${topic.name} \n KEY: ${topic.key} \n ID: ${topic.id}`,
        }));
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },
    async fetchCoursesItemsPage(page = 1, limit = this.coursesItems.pageSize) {
      try {
        this.coursesItems.loading = true;
        return this.$di.api.TtCourse.getCoursesItems({ page, limit });
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.coursesItems.loading = false;
      }
    },
    hideDialog() {
      this.confirmDialog = false;
      this.removingCourseId = null;
    },
    hideBlockDialog() {
      this.confirmBlockDialog = false;
      this.blockingCourseId = null;
    },
    openDialog(courseId) {
      this.confirmDialog = true;
      this.removingCourseId = courseId;
    },
    openBlockDialog(courseId) {
      this.confirmBlockDialog = true;
      this.blockingCourseId = courseId;
    },
    async removeCourse() {
      try {
        const response = await this.$di.api.TtCourse.archiveCourse({ id: this.removingCourseId });
        this.hideDialog();
        await this.loadCourses();

        return response;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      }
    },
    async blockCourse(id) {
      try {
        await this.$di.api.TtCourse.blockCourse({
          id,
        });
        this.coursesItems.values.find((course) => course.id === id).status = CourseStatus.STATUS_BLOCKED;
        this.hideBlockDialog();
        this.$di.notify.snackSuccess('Курс заблокирован');
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },
    async activateCourse(id) {
      try {
        await this.$di.api.TtCourse.activateCourse({
          id,
        });
        this.coursesItems.values.find((course) => course.id === id).status = CourseStatus.STATUS_ACTIVE;
        this.$di.notify.snackSuccess('Курс активирован');
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },
    addLabel(label) {
      this.course.labels.push(label);
    },
    removeLabel(label) {
      this.course.labels = this.course.labels.filter(({ key, value }) => key !== label.key && value !== label.value);
    },
    async createCourse() {
      try {
        this.courseLoading = true;
        await this.$di.api.TtCourse.createCourse({
          course: this.course,
        });
        this.$di.notify.snackSuccess('Курс создан');
        await this.loadCourses();
        this.$refs.form.reset();
        this.course.labels = [];
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.courseLoading = false;
      }
    },
    goToEdit(courseId) {
      this.$router.push({
        name: this.Names.R_LEARNING_COURSE_V2_COURSES_EDIT,
        params: {
          courseId,
        },
      });
    },
    itemClass({ status }) {
      return status === CourseStatus.STATUS_BLOCKED ? 'blocked' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.course-table {
  tr.blocked {
    td:not(.actions) {
      opacity: 0.3;
    }
  }
}

.word-break-all {
  word-break: break-all;
}
</style>
